import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import mushroom from "cem-primary-api";
import Maska from 'maska';
import { createPinia } from 'pinia'
const pinia = createPinia();

import Mapbox from "mapbox-gl";
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import "vue-json-pretty/lib/styles.css";
import toastr from "toastr";

import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';

import rate from 'vue-rate'
import 'vue-rate/dist/vue-rate.css'

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

window.toastr = require("toastr");
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "3000",
    timeOut: "2000",
    extendedTimeOut: "1000",
};

mushroom._on("$auth.loggedout", (info) => {

    if (info?.source == "fromOtherTab") {
        // người dùng đã log out ở tab khác
        // thực hiện chuyển sang trang login
        location.href = "/login"
    }
});
mushroom._on("$auth.loggedin", (token, rootUrl, info) => {

    if (info?.source == "fromOtherTab") {
        // người dùng đã log in ở tab khác
        // thực hiện chuyển sang trang chủ
        location.href = "/"
    }
});

createApp(App)
    .use(store)
    .use(router)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(ElementPlus)
    .use(rate)
    .use(pinia)
    .use(Mapbox)
    .use(vClickOutside).mount('#app')