import { defineStore } from "pinia";
export const langWeb = defineStore({
  id: "langWeb",
  state: () => ({
    lang: localStorage.language ? localStorage.language : "vi",
  }),
  actions: {
    changeLanguage(lang) {
      this.lang = lang;
    },
    changeLangVI() {
      this.lang = "vi";
      window.localStorage.setItem("language", "vi");
    },
    changeLangEN() {
      this.lang = "en";
      window.localStorage.setItem("language", "en");
    },
  },
  getters: {
    showLang: (state) => {
      if (state.lang == "vi") return "vi";
      return "en";
    },
  },
});
