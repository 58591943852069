import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../../app.config";
import { langWeb } from "@/stores/lang";
import i18n from "../i18n";
//import toastr from "toastr";
import mushroom from "cem-primary-api";
const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach(async function (to, from, next) {
  let countCheck = 0;
  const lang_web = langWeb();
  if (from.name != to.name) {
    if (to.meta.page && to.meta.page == "admin") {
      try {
        const response = await mushroom.$auth.meAsync();
        if (response.result) {
          if (response.result.roles?.length > 0) {
            response.result.roles.forEach((item) => {
              if (
                to.meta.allowAccess.includes(item) ||
                to.meta.allowAccess[0] === "*"
              ) {
                countCheck++;
              }
            });
          }
          if (countCheck === 0) {
            next("/no-permission");
          } else {
            // làm đa ngôn ngữ ở đây i18n.global.locale = "vi";
            /*Kiểm tra trong localStorage đã có key language chưa 
            nếu có rồi thì set giá trị ngôn ngữ luôn rồi nhảy xuống bước 3 */
            if (localStorage.getItem("language")) {
              i18n.global.locale = localStorage.getItem("language");
              lang_web.changeLanguage(localStorage.getItem("language"));
              next();
            } else {
              const langUserConfig = await mushroom.user_config.listAsync({
                fields: "id,key,value",
                filters: ["key=language"],
              });
              if (langUserConfig.result?.length > 0) {
                i18n.global.locale = langUserConfig.result[0].value;
                lang_web.changeLanguage(langUserConfig.result[0].value);

                localStorage.setItem(
                  "language",
                  langUserConfig.result[0].value
                );
                next();
              } else {
                if (localStorage.getItem("language_default")) {
                  i18n.global.locale = localStorage.getItem("language_default");
                  lang_web.changeLanguage(
                    localStorage.getItem("language_default")
                  );
                  next();
                } else {
                  const langSystemConfig =
                    await mushroom.system_config.listAsync({
                      fields: "id,key,value",
                      filters: ["key=language_default"],
                    });
                  if (langSystemConfig.result?.length > 0) {
                    i18n.global.locale = langSystemConfig.result[0].value;
                    lang_web.changeLanguage(langSystemConfig.result[0].value);
                    localStorage.setItem(
                      "language_default",
                      langSystemConfig.result[0].value
                    );
                    next();
                  } else {
                    i18n.global.locale = "vi";
                    lang_web.changeLanguage("vi");
                    next();
                  }
                }
              }
            }

            // next();
          }
        }
      } catch (error) {
        window.localStorage.clear();
        next("/login");
      }
    } else if (to.meta.page && to.meta.page != "admin") {
      next();
    } else {
      next("/error");
    }
  } else if (from.name && to.name && from.name == to.name) {
    next();
  } else {
    if (!to.name) {
      next("/error");
    } else next("/login");
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + " | " + appConfig.title;
  next();
});

export default router;
