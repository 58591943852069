//import mushroom, {defineAsyncResource} from "mushroomjs";
Object.defineProperty(exports, "__esModule", { value: true });
var mushroom_1 = require("mushroomjs");
var mushroom = mushroom_1.default;
var defineAsyncResource = mushroom_1.defineAsyncResource;

//export * from "mushroomjs";
for (var m in mushroom_1) exports[m] = mushroom_1[m];

//import * as AuthExtension from "mushroomjs-auth";
var AuthExtension = require("mushroomjs-auth");
//import * as FileExtension from "mushroomjs-file";
var FileExtension = require("mushroomjs-file");
//export const extensions = {
var extensions = {
    Auth: AuthExtension,
    File: FileExtension
};
exports.extensions = extensions;

"use strict";

defineAsyncResource({name:"user",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"profile",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{}},views:{}});
defineAsyncResource({name:"group",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"user_group",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"department",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"position",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"service",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"probe",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{},"_raw_http_method_get_probes":{},"_raw_http_method_update_probe_columns":{},"_raw_http_method_getGeoProbes":{},"_raw_http_method_getProbeMapInfo":{}},views:{"get_net_xs":{}}});
defineAsyncResource({name:"probe_column_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"system_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"user_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"icon",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"province",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"updatePartially":{}},views:{}});
defineAsyncResource({name:"district",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"updatePartially":{},"_raw_http_method_getGeoDistricts":{},"_raw_http_method_getDistrictMapInfo":{}},views:{}});
defineAsyncResource({name:"commune",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"table_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"quality_level",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"customer_summary",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"service_health_kpi",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"table_screen",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"usecase",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"dynamic_dashboard",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"menu",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{"get_tree":{}}});
defineAsyncResource({name:"user_manual",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"cem_log",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"updatePartially":{}},views:{}});
defineAsyncResource({name:"card",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"card_item",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"request_download_dashboard",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"_raw_http_method_downloadFile":{}},views:{}});
defineAsyncResource({name:"time_range",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});

mushroom.$using("https://cem-primary-api.test2.siten.vn/api/cem-primary/v1/");

AuthExtension.useAuth(AuthExtension.AuthLocalStorage, mushroom_1.default);
FileExtension.useFile(mushroom_1.default);

//export default mushroom;
exports.default = mushroom;